.home-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Make the container full viewport height */
    background: var(--color-light-blue);
    text-align: left;
    color: var(--color-black);
    margin: 0;
  }
  
  .home-page-container a:link, a:visited, a:hover, a:active {
    color: var(--color-black);  /* Set the text color to #eeeeee */
    font-weight: bold; /* Make the text bold */
    text-decoration: none; /* Remove the underline */
  }