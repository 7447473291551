  
  .location-details-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Make the container full viewport height */
    background: var(--color-light-blue);
    text-align: left;
    padding: 24px;
  }

  .location-details-container button { 
    font-size: 16px;
    padding: 8px 12px; /* Adjust padding if needed */
    background-color: transparent;
    border: 1px solid var(--color-black);
    border-radius: 4px;
    color: var(--color-black);
    cursor: pointer;
    transition: background-color 0.3s ease; /* Optional hover effect */
  }

  .location-details-container button:hover {
    background-color: rgba(129, 129, 129, 1);
  }

  .info-table button {
    margin-top: 0px;
  }

  .location-details-container select {
    width: calc(100% - 162px); /* Adjust as needed */
    padding: 8px;
    border: 1px solid var(--color-black);
    border-radius: 4px;
    box-sizing: border-box;
    height: 48px;            
    background-color: var(--color-white); 
    color: var(--color-black);
    width: auto;
}

/* JSON Textarea Styles */
.location-details-container textarea {
  width: 100%;  /* Adjust as needed */
  height: 96px;   /* Increase height for multiline */
  padding: 8px;
  border: 1px solid var(--color-black);
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical; /* Allow vertical resizing only */
  background-color: var(--color-white); /* Add background color to improve visibility */
  color: var(--color-black);           /* Text color for the options */
}

/* Add Structure Form Styles */
.add-structure-form {
  display: flex;
  justify-content: center; /* Horizontally center items */
  align-items: center;      /* Vertically center items */
  gap: 16px;               /* Add spacing between elements */
  margin-bottom: 24px;      /* Add space between the form and the table */
}

.add-structure-form > * {   /* Target direct children of the form */
  flex: 1;                /* Allow elements to grow (especially the textarea) */
  min-width: 150px;       /* Set a minimum width to prevent elements from shrinking too much */
}

.workout-table th, .workout-table td {
  justify-content: center; /* Horizontally center items */
  align-items: center;  
}

.players-button-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}


.players-button-container button {
  min-width: 64px;
  height: 64px;
  font-size: 24px;
}

.main-players-container {
  display: flex;
  gap: 12px;
}


.players-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
}

