  
  .workout-player-container {
    background:  var(--color-light-blue);
    font-size: 96px;
    padding: 96px;
    width: 100%;
    height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: normal;
    font-family: 'PPNikkeiMaru', sans-serif;
    box-sizing: border-box;
  }

  .workout-player-container p {
    margin: 0;
  }

  .workout-center-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }
  
  .workout-timer-circle {
    position: relative;

    width: 1200px;
    height: 1200px;
    margin: 0 auto;
  }
  
  .workout-timer-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensure it takes the full height of the circle */
    color: var(--color-dark-blue);
  }
  
  .workout-time-left {
    font-size: 240px;
    height: 290px;  
  }
  
  .workout-description-text {
    font-size: 124px;
    height: 150px;
  }

  .countdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    font-size: 164px;
  }

  .spot-pick-text {
    font-size: 100px;
  }

  .countdown-timer {
    font-size: 400px;
  }

  .exercise-progress-list {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .exercise-progress-header {
    display: flex;
    flex-direction: row;
    text-align: left;
    border-bottom: 5px solid var(--color-dark-blue);
  }

  .exercise-element {
    width: 560px;
    padding: 24px 48px;
  }

  .set-element {
    display: flex; 
    align-items: center;
    justify-content: center;
    width: 256px;
    padding: 24px 0px;
    border-left: 5px solid var(--color-dark-blue);
  }

  .exercise-progress-row {
    display: flex;
    align-items: top;
    font-size: 96px;
    border-bottom: 5px solid var(--color-dark-blue);
  }

  .workout-exercise-number {
    width: 200px;
    padding: 24px 48px;
  }

  .workout-exercise-name {
    flex-grow: 1;
    border-left: 5px solid var(--color-dark-blue);
    border-right: 5px solid var(--color-dark-blue);
    padding: 24px 48px;
  }

  .workout-exercise-reps {
    width: 300px;
    padding: 24px 48px;
  }

  .workout-footer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: absolute;
    bottom: 96px;
  }

  .celebration-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
    background-color: var(--color-cream);
  }

  .celebration-header {
    font-size: 240px;
    color: var(--color-neon-blue);
    margin: 0;
  }

  .celebration-subheader {
    color: var(--color-dark-blue);
    font-size: 120px;
    margin: 0;
  }

  .celebration-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 3128px;
    margin-top: 64px;
    gap: 96px;
  }

  .celebration-card {
    font-size: 120px;
    background: rgba(255, 255, 255, 0.75);
    padding: 48px;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 48px;
  }

  .milestone-hexagon {
    width: 224px;
    height: 250px;
    background: var(--color-light-blue);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 24px;
  }

  .milestone-number {
    color: var(--color-dark-blue);
  }

  .custom-progress-bar {
    width: 2430px;
    height: 20px;
    border-color: var(--color-dark-blue);
    background: transparent;
    overflow: hidden; /* Ensure the progress bar doesn't overflow the container */
  }

  .custom-progress-bar::-webkit-progress-bar {
    border: 5px solid; 
    background: transparent;
    border-color: var(--color-dark-blue);
  }

  .custom-progress-bar::-webkit-progress-value {
    background-color: var(--color-dark-blue);
  }

  .custom-progress-bar::-moz-progress-bar {
    background-color: var(--color-dark-blue);
  }

  .custom-progress-bar-container {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .progress-bar-container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    width: 80%;
    position: absolute;
    bottom: 249px;
  }

  .progress-bar-segment {
    height: 40px;
    border: 5px solid; 
    border-color: var(--color-dark-blue);
    position: relative; /* Add this to position the fill correctly */
  }

  .progress-bar-fill {
    height: 100%;
    background-color: var(--color-dark-blue);
    position: absolute;
    top: 0;
    left: 0;
  }

  .lifted-logo {
    width: 248px;
    height: auto; /* Maintain aspect ratio */
    color: var(--color-dark-blue); /* Set the color to grey */
    position: absolute;
    top: 128px;
  }

  .blinking {
    animation: blink-animation 1s steps(2, start) infinite;
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  /* Celebration styles */
  .celebration-milestone-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 96px;
  }

  .milestone-title {
    font-size: 164px;
    margin: 0;
    font-family: 'PPNikkeiMaru', sans-serif;
    color: var(--color-neon-blue);
  }

  .milestone-customers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 70%;
  }

  .milestone-customer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 200px;
  }

  .milestone-badge {
    display: flex;
    align-items: center;
    gap: 16px;
    color: var(--color-neon-blue);
    font-weight: 600;
  }

  .milestone-progress-container {
    width: 80%;
    position: absolute;
    bottom: 96px;
  }

  .milestone-progress-bar {
    height: 40px;
    border: 5px solid var(--color-dark-blue);
    position: relative;
    width: 100%;
  }

  .milestone-progress-fill {
    height: 100%;
    background-color: var(--color-dark-blue);
    position: absolute;
    top: 0;
    left: 0;
    transition: width 1s linear;
  }

  .no-milestones {
    text-align: center;
    font-size: 72px;
    color: var(--color-grey);
    margin: 64px 0;
  }

  .celebration-bg {
    background-color: var(--color-cream) !important;
  }

  /* Challenge styles */
  .challenge-bg {
    background-color: var(--color-light-blue) !important;
  }

  .challenge-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
    width: 100%;
    height: 100%;
  }

  .challenge-top-element {
    display: flex;
    flex-direction: column;
    gap: 64px;
  }

  .challenge-timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
  }

  .challenge-timer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .challenge-time-left {
    font-size: 124px;
  }

  .challenge-progress-bar-container {
    width: 750px;
  }

  .challenge-progress-bar {
    height: 40px;
    border: 5px solid var(--color-dark-blue);
    position: relative;
    width: 100%;
  }

  .challenge-progress-bar-fill {
    height: 100%;
    background-color: var(--color-dark-blue);
    position: absolute;
    top: 0;
    left: 0;
    transition: width 1s linear;
  }


  .challenge-exercises {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 48px;
    width: 100%;
  }

  .challenge-exercise {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .exercise-video-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid var(--color-black);
    padding: 12px 12px;
  }

  .exercise-video {
    height: 700px;
    width: 700px; 
    display: flex; 
    justify-content: center; 
    overflow: hidden;
    box-sizing: border-box;
  }

  .exercise-video video {
    height: 100%; /* Fill the height of the container */
    width: auto; /* Maintain aspect ratio and crop width if necessary */
  }


