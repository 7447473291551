@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

.player-container {
  display: flex;
  flex-direction: column;
  background: var(--color-light-blue); /* Match the gradient from timer-element-box */
  color: var(--color-black);
  font-size: 36px;
  min-height: 100vh;
  position: relative;
  align-items: center;
  gap: 48px;
  padding: 48px;
  line-height: normal;
}

.player-headline {
  font-size: 24px;
  text-align: left; /* Align text to the left */
  font-weight: bold;
  background: linear-gradient(0deg, var(--color-black) 0%, #828282 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.player-centered-content {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.player-timer-element {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.player-coach-timer-description-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 0px;
}

.player-interval-type {
  font-weight: bold;
  background: linear-gradient(0deg, var(--color-black) 0%, #828282 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.player-exercise-number {
  background: linear-gradient(0deg, var(--color-black) 0%, #828282 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.player-coach-timer-circle {
  position: relative;
  width: 500px;
  height: 500px;
  margin: 20px 0;
  background-image: url('../public/coach-circle-background.svg'); /* Reference the image in the public folder */
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the image */
}

.player-coach-timer-circle svg {
  display: block;
  width: 100%;
  height: 100%;
}


.player-coach-timer-text {
  position: absolute;
  font-size: 80px;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(0deg, #1C1C1C 0%, #828282 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.player-skip-button,
.player-start-button,
.player-replay-button {
  width: 100px;
  height: 100px;
  border-radius: 16px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 4px;
}

.player-skip-button {
  border: 4px solid #1c1c1c;
  background: none;
  color: #1c1c1c;
}

.player-start-button,
.player-replay-button {
  background-color: #1c1c1c;
  color: #ffffff;
}