.info-table {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    border-collapse: collapse;

}

.info-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 24px;
}