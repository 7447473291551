

.admin-form-container { /* Container for the entire form */
  padding: 24px; 
  margin: auto;
  min-height: 100vh; /* or set an explicit width */
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  background: var(--color-light-blue); /* Match the gradient from timer-element-box */
  color: var(--color-black);
}

.admin-form-container h2 { /* Form heading style */
  text-align: left;
  margin-bottom: 20px;
}

/* Button styles (same as your other pages) */
.admin-form-container button { 
  font-size: 16px;
  padding: 8px 12px; /* Adjust padding if needed */
  background-color: transparent;
  border: 1px solid var(--color-black);;
  border-radius: 4px;
  color: var(--color-black);
  cursor: pointer;
  transition: background-color 0.3s ease; /* Optional hover effect */
  margin-bottom: 24px;
  margin-top: 24px;
}

.admin-form-container button:hover {
  background-color: rgba(129, 129, 129, 1);
}

/* Input field styles */
.admin-form-container label {
    text-align: left;        /* Right-align the label text */
    margin-right: 12px;
    width: 150px;       /* Add space between label and input */
  }

  .admin-form-container .playlist-links div {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

.admin-form-container table {
  margin-bottom: 24px;
}

.admin-form-container input,
.admin-form-container select,
.admin-form-container textarea {
  flex: 1; /* Adjust input width to accommodate label and margin */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;  /* Include padding and border in the element's total width and height */
  height: 36px;            /* Set input height */
}

.add-element-form {
  display: flex;
  flex-direction: row; /* Align items vertically */
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  height: 48px;
}