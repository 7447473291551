.navbar {
  background: var(--color-light-blue); /* Match the gradient from timer-element-box */
  color: var(--color-black);
  padding: 16px 24px 16px 24px;
  position: fixed; /* Stay on top of the page */
  top: 0;
  width: 100%;
  z-index: 8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; /* Adjust font size if needed */
  padding: 8px 12px; /* Adjust padding if needed */
  background-color: transparent;
  border: 1px solid var(--color-black);
  border-radius: 4px;
  color: var(--color-black);
  cursor: pointer;
  transition: background-color 0.3s ease; /* Optional hover effect */
}

.navbar button:hover {
  background-color: rgba(129, 129, 129, 1);
}

/* Logout Button */
.logout-button {
  position: absolute;
  bottom: 24px; /* Align with navbar padding */
  left: 24px; /* Align with navbar padding */
  background-color: transparent; /* Transparent background */
  border: 1px solid var(--color-black); /* White border */
  border-radius: 4px;
  color: var(--color-black); /* Light gray text color */
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px; /* Adjust padding to match other buttons */
  transition: background-color 0.3s ease; /* Hover effect */
}

.logout-button:hover {
  background-color: rgba(129, 129, 129, 1); /* Darker gray on hover */
}


.sidebar-menu {
  /* Style the sidebar menu */
  height: 100%;
  width: 0; /* Hidden by default */
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  background: var(--color-light-blue); /* Match the gradient from timer-element-box */
  color: var(--color-black);
  overflow-x: hidden;
  transition: width 0.3s; /* Add a smooth transition effect */
  font-size: 18px; /* Adjust font size as needed */
}

.sidebar-menu.open {
  width: 250px; /* Adjust the width as needed */
  border-right: var(--color-black) solid 1px;
}

.sidebar-menu button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; /* Adjust font size if needed */
  padding: 8px 12px; /* Adjust padding if needed */
  background-color: transparent;
  border: 1px solid var(--color-black);
  border-radius: 4px;
  color: var(--color-black);
  cursor: pointer;
  transition: background-color 0.3s ease; /* Optional hover effect */
}

.sidebar-menu button:hover {
  background-color: rgba(129, 129, 129, 1);
}

.menu-close-button {
  /* Style your close button */
  position: absolute;
  top: 10px;
  right: 10px;
}

.title {
  padding: 24px;
}

.menu-items {
  display: flex;
  flex-direction: column;
}

.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  gap: 8px;
  transition: background-color 0.3s; /* Add a hover effect */
  padding: 8px 24px 8px 24px;
}

.menu-item:hover {
  background-color: var(--color-black); /* Adjust hover background color as needed */
  color: var(--color-light-blue);
}

.menu-icon {
  margin-right: 10px; /* Adjust space between icon and text as needed */
}

.menu-items a:hover {
  text-decoration: none;
  color: var(--color-light-blue);
}
