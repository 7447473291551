.admin-list-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Make the container full viewport height */
  background: var(--color-light-blue); /* Match the gradient from timer-element-box */
  color: var(--color-black);
  text-align: left;
  padding: 24px; /* Add padding to the container */
  gap: 24px;
}

.admin-list-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; /* Adjust font size if needed */
  padding: 8px 12px; /* Adjust padding if needed */
  background-color: transparent;
  border: 1px solid var(--color-black);
  border-radius: 4px;
  color: var(--color-black);
  cursor: pointer;
  transition: background-color 0.3s ease; /* Optional hover effect */
}

.admin-list-container button:hover {
  /* Optional hover effect */
  background-color: rgba(129, 129, 129, 1);
}

.admin-list-container a:hover {
  color: inherit;
  text-decoration: underline;
}

.admin-list-container .scrollable-table-container { 
  overflow-x: auto;  /* Enable horizontal scrolling when needed */
}

.week-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
}

.filter-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.filter-row button {
  margin: 0;
}

.admin-list-table {
  width: 100%; 
  border-collapse: collapse;
  margin-top: 24px;
  margin-bottom: 24px;
}

.admin-list-table input[type=number] {
  width: 78px;
}

.admin-list-table th,
.admin-list-table td {
  border: 1px solid var(--color-black); /* Add borders to cells */
  padding: 4px 8px 4px 8px;
  text-align: left;
}

.admin-list-table th {
  background-color: var(--color-black); 
  color: var(--color-white);
  border: 1px solid var(--color-black);
  padding: 8px;
  text-align: left;
}

.admin-list-table .flex{
  display: flex;
  flex-direction: row;
}

/* Action Buttons Styling (flexbox within the grid cell) */
.admin-list-table .actions { /* Scoped to the table */
  display: flex; 
  justify-content: left; 
  align-items: center;  
  gap: 8px;
  padding: 4px 0px 4px 0px;
}

.admin-list-table .actions button {
  margin: 0px;
}

.admin-list-table .color-options { /* Scoped to the table */
  display: flex; /* Enable flexbox for color options */
  justify-content: space-around; /* Distribute color options evenly */
  align-items: center;
  gap: 10px; 
  min-width: 220px;
}

.horizontal-buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.horizontal-buttons a {
 color: inherit;
 text-decoration: none;
}

.horizontal-buttons a:hover {
  color: inherit;
  text-decoration: none;
  font-weight:normal;
 }

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px; 
  margin-top: 16px; 
}

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 52px !important; 
  height: 24px; 
  margin-right: 12px; 
}

.toggle input {
  opacity: 0; /* Hide the default checkbox */
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff; 
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px; 
  width: 16px; 
  left: 4px; 
  bottom: 4px; 
  background-color: var(--color-black); 
  border-radius: 50%; 
  transition: .4s; 
}

input:checked + .slider {
  background-color: var(--color-black); 
}

input:checked + .slider:before {
  transform: translateX(28px); 
  background-color: var(--color-white);
}

.admin-list-table tr.archived {
  color: var(--color-grey);
  font-style: italic;
}
