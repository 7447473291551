
.login-signup-container {
    /* Style the main container for the login/signup page */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 350px; /* Adjust the width as needed */ 
  }
  
  .login-signup-container h1 {
    margin-bottom: 20px; /* Add spacing below the heading */    
  }
  
  .login-signup-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  .login-signup-container input[type="email"],
  .login-signup-container input[type="password"],
  .login-signup-container input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; 
    flex-grow: 1;
  }
  
  .login-signup-container button {
    background-color: #0f100f; /* Example color - customize to your liking */
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%; /* Make buttons full width */
    margin-bottom: 24px;
  }

.login-signup-container button span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  
}

  
  .login-signup-container button:hover {
    opacity: 0.8;
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center; /* Center text within the separator */
    margin: 20px 0; 
    position: relative; /* Add this to position the pseudo-element */
  }
  
  .separator::before,
  .separator::after { 
    content: '';
    flex-grow: 1;
    border-top: 1px solid #ccc; /* Light gray line */
    margin: 0 10px; 
  }
  
  .separator .or-text {
    color: #888; /* Make the "or" text gray */
    position: relative; /* Add this to position the "or" text */
    z-index: 1; /* Ensure the text is above the lines */
    background-color: white; /* Set background to white to cover the overlapping lines */
    padding: 0 10px; /* Add some padding to make space around the "or" text */
  }
  
  /* Optional: Add a bit more width to the separator lines */
  .separator::before, 
  .separator::after {
    width: 45%; /* Adjust this value to your preference */
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }

.password-container {
  margin-bottom: 12px;
}

.password-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.password-input input {
  padding-right: 40px; /* Add padding to the right for the icon */
}

.password-toggle-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.forgot-password {
  color: #828282; /* Customize the color */
  cursor: pointer;
  text-align: left;
}

.forgot-password:hover {
  text-decoration: underline;
}