.timer-page-container {
    display: flex;
    min-height: 100vh; /* Make the container full viewport height */
    background: var(--color-light-blue); /* Match the gradient from timer-element-box */
    color: var(--color-black);
    font-weight: bold;
  }

  .timer-page-container button {
    display:flex;
    align-items:center;
    justify-content: center;
    font-size: 16px; /* Adjust font size if needed */
    padding: 8px 12px; /* Adjust padding if needed */
    background-color: transparent;
    border: 1px solid var(--color-black);
    border-radius: 4px;
    color: var(--color-black);
    cursor: pointer;
    transition: background-color 0.3s ease; /* Optional hover effect */
  }
  
  .timer-page-container button:hover { /* Optional hover effect */
    background-color: rgba(129, 129, 129, 1);
  }

  .timer-page-container .left-column {
    width: 35%; /* Left column takes 30% width */
    padding: 24px; /* Add some padding for visual comfort */
    border-right: 1px solid var(--color-black); /* Add a subtle divider between columns */
  }

  .timer-page-container .right-column {
    width: 65%; /* Right column takes 70% width */ 
  }

  .timer-list {
    list-style: none; /* Remove bullet points */
    padding: 0;       /* Reset default list padding */
  }

  .timer-list-item {
    display: flex;    /* Arrange items in a row */
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px; /* Add padding around each item */
    border-bottom: 1px solid var(--color-black); /* Add a bottom border for separation */
  }
  .timer-list-buttons {
    display: flex; /* Arrange buttons horizontally */
    gap: 8px; /* Add 8px spacing between buttons */
  }
