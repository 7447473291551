/* Generic Page Setup*/
.flex-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.centered {
  align-items: center !important;
}

.top-left {
  position: absolute;
  top: 40px;
  left: 40px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.space-between {
  justify-content: space-between !important;
  gap: 8px;
}

.space-around {
  justify-content: space-around !important;
  gap: 8px;
}

.gap-xxs {
  gap: 8px;
}

.gap-xs {
  gap: 12px;
}

.gap-s {
  gap: 24px;
}

.gap-m {
  gap: 48px;
}

.gap-l {
  gap: 64px;
}

.gap-xl {
  gap: 80px;
}

.icon-m {
  font-size: 36px !important;
}

.icon-l {
  font-size: 48px !important;
}

.icon-xl {
  font-size: 64px !important;
}

/* Specific Page Setup */
.location-tablet {
  box-sizing: border-box;
  padding: 64px;
  color: var(--color-black);
  background-color: var(--color-light-blue);
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;
  gap: 64px;
  font-size: 40px;
  min-height: 100vh;
  font-family: "PTSans", sans-serif;
  line-height: normal;
  position: relative;
}

.top-info-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  height: 80px;
  width: 100%;
}

.error-message-s {
  font-size: 24px;
  color: red;
}

.exercise-list {
  font-size: 48px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 80px;
}

.weight-element {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-end;
  align-items: center;
}

.weight-element button {
  width: 140px;
}

.weight-value {
  width: 100px;
  text-align: right;
}

.spots-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  width: 80%;
  gap: 40px;
}

.exercise-name {
  max-width: 45%;
}

.workout-number-circle {
  position: absolute;
  bottom: -5%;
  right: -5%;
  background-color: #ffea00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  z-index: 1;
  width: auto; 
  height: auto;
  min-width: 32px;
  min-height: 32px;
  padding: 4px;
  aspect-ratio: 1;
}

/* Texts */

.location-tablet h1 {
  margin: 0;
  font-family: "PPNikkeiMaru", sans-serif;
  font-size: 96px;
}

.location-tablet h2 {
  margin: 0;
  font-family: "PPEditorialNew";
  font-size: 72px;
}

.location-tablet h3 {
  margin: 0;
  font-family: "PPNikkeiMaru";
  font-size: 72px;
}

.location-tablet p {
  margin: 0;
  font-family: "PTSans", sans-serif;
}

.text-s {
  font-size: 24px;
}

/* Buttons */
.button-s {
  background: var(--color-black);
  color: var(--color-light-blue);
  padding: 24px;
  height: 64px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.button-m {
  background: var(--color-black);
  color: var(--color-light-blue);
  padding: 24px;
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.button-m.secondary {
  background: none;
  border: 3px solid var(--color-black);
  color: var(--color-black);
}

.button-s.secondary {
  background: none;
  border: 3px solid var(--color-black);
  color: var(--color-black);
}

.button-m.secondary.square {
  width: 80px;
}

.button-l {
  background: var(--color-black);
  color: var(--color-light-blue);
  padding: 24px;
  height: 100px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.special {
  background-color: var(--color-neon-blue);
  color: var(--color-off-white);
}

.button-l {
  width: 400px;
  height: 200px;
  max-width: 400px;
  max-height: 200px;
  background-color: var(--color-black);
  color: var(--color-off-white);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  gap: 8px;
}

.button-l.square {
  width: 200px;
}

.button-l.secondary {
  border: 8px solid var(--color-black);
  background: none;
  color: var(--color-black);
}

.button-circle {
  width: 124px;
  height: 124px;
  border-radius: 50%;
  color: var(--color-black);
  border: 3px solid var(--color-black);
  background: transparent;
  font-family: "PPNikkeiMaru", sans-serif;
  font-size: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button-circle:disabled {
  color: var(--color-grey);
  border-color: var(--color-grey);
}

.button-circle.large {
  width: 100px;
  height: 100px;
  font-size: 50px;
}

/* Cards */
.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 24px;
  width: 100%;
}

.card-s {
  display: flex;
  align-items: center;
  background-color: var(--color-black);
  color: white;
  padding: 24px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  cursor: pointer;
  gap: 24px;
}

.card-s-name-element {
  width: 30%;
}

.card-s-center-element {
  width: 20%;
}

.card-s-right-element {
  flex-grow: 1;
  text-align: right;
}

.card-l {
  width: calc(50% - 12px);
  height: 212px;
  display: flex;
  align-items: center;
  background-color: var(--color-black);
  color: var(--color-light-blue);
  padding: 24px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold;
  box-sizing: border-box;
  gap: 24px;
}

/* States */
.active {
  background-color: var(--color-neon-blue) !important;
  color: var(--color-off-white) !important;
}

.outlined {
  border: 3px solid var(--color-black);
  background: none;
  color: var(--color-black);
}

/* Images & visual elements */
.profile-avatar-s,
.profile-placeholder-s {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
  background-color: var(--color-light-blue);
  border: 3px solid var(--color-black);
}

.profile-avatar-l,
.profile-placeholder-l {
  width: 164px;
  height: 164px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light-blue);
  color: var(--color-black);
  font-size: 1em;
  border: 3px solid var(--color-black);
  position: relative;
}


.profile-avatar-xl,
.profile-placeholder-xl {
  width: 164px;
  height: 164px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light-blue);
  color: var(--color-black);
  font-size: 1em;
  border: 3px solid var(--color-black);
}

.circled-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  background: var(--color-neon-blue);
  color: var(--color-off-white);
  border-radius: 50%;
  margin-top: 180px;
}

.coach-timer-circle {
  position: relative;
  width: 360px;
  height: 360px;
  margin: 20px 0;
}

.mobile-timer-circle {
  display: none;
}

.coach-timer-text {
  position: absolute;
  font-size: 48px;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Input fields */
.input-field {
  flex-grow: 1;
  height: 64px;
  border: none;
  border-bottom: 2px solid var(--color-black);
  padding: 0;
  outline: none;
  background: transparent;
}

.input-field::placeholder {
  color: var(--color-grey);
  opacity: 0.5;
}
@media (max-width: 1110px) {
  .location-tablet {
    padding: 40px;
    gap: 40px;
    font-size: 32px;
  }

  .exercise-list {
    font-size: 32px;
    gap: 64px;
  }

  .location-tablet h1 {
    font-size: 72px;
  }

  .location-tablet h2 {
    font-size: 56px;
  }

  .location-tablet h3 {
    font-size: 56px;
  }

  .top-info-buttons {
    margin-bottom: 12px;
    gap: 12px;
  }

  .weight-element {
    gap: 12px;
  }

  .spots-container {
    gap: 24px;
  }

  /* Buttons */
  .button-m {
    padding: 12px;
    font-size: 32px;
    gap: 8px;
  }

  .button-l {
    height: 164px;
    gap: 4px;
  }

  .button-l.square {
    width: 164px;
  }

  .button-circle {
    width: 84px;
    height: 84px;
    font-size: 48px;
  }

  .profile-avatar-l,
  .profile-placeholder-l {
    width: 96px;
    height: 96px;
  }

  .profile-avatar-xl,
  .profile-placeholder-xl {
    width: 124px;
    height: 124px;
  }
  
}

.card-l {
  padding: 20px;
  gap: 12px;
  height: 164px;
}

.card-s {
  padding: 20px;
  gap: 24px;
}

@media (max-width: 450px) {
  .location-tablet {
    padding: 24px;
    gap: 12px;
    font-size: 16px;
  }

  .exercise-list {
    font-size: 16px;
  }

  .weight-element {
    gap: 12px;
  }

  .spots-container {
    gap: 14px;
  }

  .input-field {
    height: 40px;
  }
  .coach-timer-circle {
    width: 164px;
    height: 164px;
    margin: 10px 0;
  }

  .coach-timer-text {
    font-size: 24px;
  }

  .mobile-timer-circle {
    display: block;
  }

  .desktop-timer-circle {
    display: none;
  }

  .location-tablet h1 {
    font-size: 32px;
  }

  .location-tablet h2 {
    font-size: 24px;
  }

  .location-tablet h3 {
    font-size: 24px;
  }

  .text-s {
    font-size: 12px;
  }

  .icon-m {
    font-size: 24px !important;
  }

  .icon-l {
    font-size: 32px !important;
  }

  .icon-xl {
    font-size: 40px !important;
  }

  .card-s {
    padding: 12px;
    width: 100%;
    gap: 12px;
  }

  .card-l {
    width: calc(50% - 6px);
    height: 96px;
    padding: 12px;
    gap: 12px;
  }

  .card-list {
    gap: 12px;
  }

  /* Buttons */
  .button-s {
    padding: 12px;
    font-size: 16px;
    gap: 8px;
    height: 48px;
  }


  .button-m {
    padding: 12px;
    font-size: 16px;
    gap: 8px;
    height: 48px;
  }

  .button-m.secondary.square {
    width: 48px;
  }

  .button-circle {
    width: 48px;
    height: 48px;
    font-size: 24px;
  }

  .button-circle.large {
    width: 64px;
    height: 64px;
    font-size: 32px;
  }

  .button-l {
    height: 80px;
    gap: 4px;
  }

  .button-l.square {
    width: 80px;
  }

  .button-l.secondary {
    border: 4px solid var(--color-black);
  }

  .profile-avatar-s,
  .profile-placeholder-s {
    width: 48px;
    height: 48px;
  }

  .profile-avatar-l,
  .profile-placeholder-l {
    width: 56px;
    height: 56px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

.modal-content {
  background: white;
  border: 2px solid black;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 80%;
}

.modal-buttons {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  gap: 24px;
}

.highlighted-exercise {
  border: 5px solid var(--color-black);
  padding: 12px;
}

.workout-complete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  gap: 1rem;
}

.music-selector {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-light-blue);
  z-index: 1000;
  padding: 40px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 24px;
}

.playlist-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.playlist-list::-webkit-scrollbar {
  display: none;
}

.playlist-item {
  background-color: var(--color-off-white);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.playlist-image {
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 4px;
}

.playlist-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playlist-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.playlist-item h3 {
  margin: 0 0 8px 0;
  font-size: 24px;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playlist-item p {
  margin: 0 0 16px 0;
  color: var(--color-grey);
  flex: 1;
}

.playlist-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
}

/* Add responsive styles */
@media (max-width: 450px) {
  .playlist-item {
    flex-direction: column;
  }

  .playlist-image {
    width: 100%;
    height: 200px;
  }
}

/* Corrections toggle styling */
.tablet-toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed var(--color-black);
  padding: 12px;
}

.tablet-toggle-label {
  font-size: 24px;
  font-style: italic;
}

.tablet-toggle {
  position: relative;
  display: inline-block;
  width: 64px !important; 
  height: 32px; 
}

.tablet-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.tablet-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-white); 
  transition: .4s;
  border: 2px solid var(--color-black);
  border-radius: 32px;
}

.tablet-slider:before {
  position: absolute;
  content: "";
  height: 22px; 
  width: 22px; 
  left: 4px; 
  bottom: 3px; 
  background-color: var(--color-black); 
  transition: .4s; 
  border-radius: 50%;
}

input:checked + .tablet-slider {
  background-color: var(--color-neon-blue); 
}

input:checked + .tablet-slider:before {
  transform: translateX(32px); 
  background-color: var(--color-white);
}
