/* WorkoutWeightDisplay.css */
.workout-weight-display {
    background-color: var(--color-light-blue);
    color: var(--color-black);
    font-size: 96px;
    padding: 96px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
    font-family: 'PPNikkeiMaru', sans-serif;
    box-sizing: border-box;
    line-height: normal;
}

.blank {
    background-color: var(--color-black);
}

.loading-message {
    font-size: 72px;
}


.exercise-customer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    height: 100%;
    width: 100%;
}

.exercise-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 360px;
}

.weight-timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
}

.weight-time-left {
    font-size: 124px;
}

.weight-timer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.weight-description-text {
    font-size: 64px;
}

.weight-progress-bar-container {
    width: 750px;
}

.weight-progress-bar {
    height: 40px;
    border: 5px solid var(--color-black);
    position: relative;
    width: 100%;
}

.weight-progress-bar-fill {
    height: 100%;
    background-color: var(--color-black);
    position: absolute;
    top: 0;
    left: 0;
    transition: width 1s linear;
}

.station-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
}

.exercise-video-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid var(--color-black);
    padding: 12px 12px;
}

.exercise-video {
    height: 700px;
    width: 700px; 
    display: flex; 
    justify-content: center; 
    overflow: hidden;
    box-sizing: border-box;
}

.exercise-video video {
    height: 100%; /* Fill the height of the container */
    width: auto; /* Maintain aspect ratio and crop width if necessary */
}

/* Set Progress Styles */
.weight-set-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 750px;
    min-width: 750px;
}

.weight-set-progress-header {
    display: flex;
    flex-direction: row;
    border: 5px solid var(--color-black);
    border-bottom: 0px;
}

.weight-set-progress-row {
    display: flex;
    flex-direction: row;
    min-height: 164px;
    border: 5px solid var(--color-black);
}

.weight-set-element {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 256px;
    padding: 24px;
    border-right: 5px solid var(--color-black);
    font-size: 64px;
}

.weight-set-element:last-child {
    border-right: none;
}

/* Icon styles */
.weight-set-element svg {
    width: 64px;
    height: 64px;
}

.weight-set-element .blinking {
    color: var(--color-black);
    animation: blink-animation 1s steps(2, start) infinite;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.customer-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 80px;
    width: 100%;
}

.customer-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 5px solid var(--color-black);
    padding: 48px;
    min-height: 750px;
    gap: 48px;
}

.spot-label {
    font-weight: bold;
}

.customer-weight {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.plate-suggestion {
    font-size: 80px;
    margin-top: 12px;
    color: var(--color-dark-blue);
}

.error-message {
    text-align: center;
    font-size: 96px;
    color: #FF0000;
}

/* Add these new styles */
.customer-details {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: auto;  /* This will push the details to the bottom */
}

.workout-number,
.language,
.corrections {
    font-size: 40px;
    border: 5px solid var(--color-black);
    border-radius: 50px;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.filled {
    font-size: 60px;
    color: var(--color-white);
    background-color: var(--color-black);
}

.workout-number.first-workout {
    background-color: var(--color-yellow);
}

.workout-number.milestone-workout {
    background-color: var(--color-black);
    color: var(--color-light-blue);
}

.coach-name {
    font-size: 64px;
    margin-top: 48px;
}

.blank .coach-name {
    color: var(--color-light-blue);
}

.blank-timer-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  gap: 1rem;
}

.blank-interval-type {
  font-size: 6rem;
  font-weight: 500;
}

.blank-timer {
  font-size: 14rem;
  font-weight: 700;
  font-family: 'PPNeueMontreal', sans-serif;
}

/* Thank you screen styles */
.thank-you-display {
  background-color: var(--color-cream) !important;
  color: var(--color-dark-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Add celebration styles from WorkoutPlayer.css */
.celebration-bg {
    background-color: var(--color-cream) !important;
}

.celebration-milestone-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 96px;
}

.milestone-title {
    font-size: 164px;
    margin: 0;
    font-family: 'PPNikkeiMaru', sans-serif;
    color: var(--color-neon-blue);
}

.milestone-customers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 70%;
}

.milestone-customer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 200px;
}

.milestone-customer-name {
    font-size: 96px;
    color: var(--color-dark-blue);
}

.milestone-badge {
    display: flex;
    align-items: center;
    gap: 16px;
    color: var(--color-neon-blue);
    font-weight: 600;
}

.milestone-progress-container {
    width: 80%;
    position: absolute;
    bottom: 96px;
}

.milestone-progress-bar {
    height: 40px;
    border: 5px solid var(--color-dark-blue);
    position: relative;
    width: 100%;
}

.milestone-progress-fill {
    height: 100%;
    background-color: var(--color-dark-blue);
    position: absolute;
    top: 0;
    left: 0;
    transition: width 1s linear;
}

.no-milestones {
    text-align: center;
    font-size: 72px;
    color: var(--color-grey);
    margin: 64px 0;
}

/* Challenge styles */
.challenge-bg {
    background-color: var(--color-light-blue);
}

.challenge-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 124px;
    width: 100%;
    height: 100%;
}

.challenge-top-element {
    display: flex;
    flex-direction: column;
    gap: 64px;
}

.challenge-exercises {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.challenge-exercise {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: 48px;
}

.workout-footer {
    margin-top: auto;
    font-size: 64px;
    text-align: center;
}
