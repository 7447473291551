@import './variables.css';

@font-face {
  font-family: 'PPNikkeiMaru';
  src: url('../public/fonts/PPNikkeiMaru-Regular.woff2') format('woff2'),
       url('../public/fonts/PPNikkeiMaru-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PPEditorialNew';
  src: url('../public/fonts/PPEditorialNew-Regular.woff2') format('woff2'),
       url('../public/fonts/PPEditorialNew-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: var(--font-body);
  color: var(--color-black);
  background-color: var(--color-light-blue);
  margin: 0;
}

p {
  margin: 0;
}

h1,h2,h3,h4 {
  margin: 0;
  font-family: 'PPNikkeiMaru', sans-serif;
  font-weight: normal;
}

h5, h6 {
  margin: 0;
}

input {
  font-family: var(--font-body);
  border: none;
  border-bottom: 2px solid black;
  background-color: transparent;
  padding: 8px;
}

input:focus {
  outline: none; /* Ensure no outline on focus */
}

.inverted {
  filter: invert(1);
}

.default-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;    
  flex-direction: column;
  background-color: var(--color-light-blue);
  gap: 64px;
}

.neon-background {
background-color: var(--color-neon-blue);
}